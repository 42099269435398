exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-ru-js": () => import("./../../../src/pages/about.ru.js" /* webpackChunkName: "component---src-pages-about-ru-js" */),
  "component---src-pages-advantages-js": () => import("./../../../src/pages/advantages.js" /* webpackChunkName: "component---src-pages-advantages-js" */),
  "component---src-pages-advantages-ru-js": () => import("./../../../src/pages/advantages.ru.js" /* webpackChunkName: "component---src-pages-advantages-ru-js" */),
  "component---src-pages-construction-js": () => import("./../../../src/pages/construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-construction-ru-js": () => import("./../../../src/pages/construction.ru.js" /* webpackChunkName: "component---src-pages-construction-ru-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-contacts-ru-js": () => import("./../../../src/pages/contacts.ru.js" /* webpackChunkName: "component---src-pages-contacts-ru-js" */),
  "component---src-pages-cottages-js": () => import("./../../../src/pages/cottages.js" /* webpackChunkName: "component---src-pages-cottages-js" */),
  "component---src-pages-cottages-ru-js": () => import("./../../../src/pages/cottages.ru.js" /* webpackChunkName: "component---src-pages-cottages-ru-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-documents-ru-js": () => import("./../../../src/pages/documents.ru.js" /* webpackChunkName: "component---src-pages-documents-ru-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-infrastructure-js": () => import("./../../../src/pages/infrastructure.js" /* webpackChunkName: "component---src-pages-infrastructure-js" */),
  "component---src-pages-infrastructure-ru-js": () => import("./../../../src/pages/infrastructure.ru.js" /* webpackChunkName: "component---src-pages-infrastructure-ru-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-ru-js": () => import("./../../../src/pages/news.ru.js" /* webpackChunkName: "component---src-pages-news-ru-js" */),
  "component---src-pages-ru-404-js": () => import("./../../../src/pages/ru/404.js" /* webpackChunkName: "component---src-pages-ru-404-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-card-js": () => import("./../../../src/templates/card.js" /* webpackChunkName: "component---src-templates-card-js" */)
}

